import React from 'react';
import { Tab, BooleanField, TextField, ReferenceField, ReferenceManyField, NumberField } from 'react-admin';

import { ShowActions } from '../../common/Show';
import DateField from '../../common/fields/DateField';
import StarlinkUsageGraph from '../../starlink_usages/StarlinkUsageGraph';
import StarlinkTerminalRow from '../../starlink_terminals/StarlinkTerminalRow';
import ServiceProvisionButton from '../ServiceActionsButtons/ServiceProvisionButton';

const StarlinkServiceActions = (props) => (
    <ShowActions {...props}>
        <ServiceProvisionButton record={props.data} />
    </ShowActions>
);

const StarLinkServiceShow = (props) => (
    <Tab label="summary" {...props}>
        <TextField source="status" />
        <ReferenceField label="Assignee" source="assignee_id" reference="users" allowEmpty>
            <TextField source="email" />
        </ReferenceField>
        <ReferenceField label="Package" source="package_id" reference="packages" link="show" allowEmpty>
            <TextField source="reference" />
        </ReferenceField>
        <TextField source="reference" />
        <TextField source="customer_reference" />
        <ReferenceField label="Last Invoice" source="last_invoice_id" reference="invoices" link="show" allowEmpty>
            <TextField source="id" />
        </ReferenceField>
        <TextField source="name" />
        <TextField source="type" />
        <TextField source="frequency" />
        <TextField source="product_name" />
        <ReferenceField label="Site" source="site_id" reference="sites" link="show" allowEmpty>
            <TextField source="name" />
        </ReferenceField>
        <DateField source="start_date" />
        <DateField source="end_date" />
        <TextField source="end_reason" />
        <DateField source="early_end_date" label="Commitment date" />
        <TextField source="notes" options={{ multiline: true }} rows="4" />
        <ReferenceField label="StarLink Service Line" source="starlink_service_line_id" reference="starlink_service_lines" link="show" allowEmpty>
            <TextField source="service_line_number" />
        </ReferenceField>
        <BooleanField source="starlink_service_line.last_service_plan.isOptedIntoOverage" label="StarLink Opted Into Overrage" />
        <NumberField label="Last Month Usage (GB)" source="last_usage" />
        <NumberField label="Current Month Usage (GB)" source="current_usage" />
        <NumberField source="starlink_service_line.last_service_plan.overageLine.usageLimitGB" label="Service Plan Usage Limit(GB)" />
        <TextField source="starlink_service_line.last_service_plan.productId" label="Service Plan Product" />
        <TextField source="starlink_service_line.last_service_plan.isoCurrencyCode" label="Currency" />
        <NumberField source="starlink_service_line.last_service_plan.overageLine.overageAmountGB" label="Overrage(GB)" />
        <NumberField source="starlink_service_line.last_service_plan.overageLine.overagePrice" label="Overrage Price" />
        <NumberField source="starlink_service_line.last_service_plan.overageLine.pricePerGB" label="Overrage Price/GB" />
        <NumberField label="Data Alert (GB)" source="data_limit" />
        <TextField label="Data Alert Contacts" source="data_limit_contacts" />
        <DateField label="Created at" source="created_at" />
        <DateField label="Updated at" source="updated_at" />
        <ReferenceManyField addLabel={false} reference="starlink_terminals" target="starlink_service_line_id" source="starlink_service_line_id">
            <StarlinkTerminalRow />
        </ReferenceManyField>
        <StarlinkUsageGraph />
    </Tab>
);

export default StarLinkServiceShow;
export { StarlinkServiceActions };
