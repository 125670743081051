import React from 'react';
import { TabbedShowLayout, Tab, ReferenceField, TextField, BooleanField} from 'react-admin';
import { Helmet } from 'react-helmet';

import Show, { ShowActions } from '../common/Show';
import DateField from '../common/fields/DateField';
import CustomerReferenceField from '../customers/CustomerReferenceField';
import BackToStockButton from './ProductItemActionsButtons/BackToStockButton';
import ProductItemMoveButton from './ProductItemActionsButtons/ProductItemMoveButton';
import FilteredLogList from '../logs/FilteredLogList';


const ProductItemTitle = ({ record }) => {
	const serial = record.serial ? record.serial.split('-').pop() : '';

	return (
		<>
			<Helmet>
				<title>{`KB - Product Item[${serial}] ${record.customer ? '- '+ record.customer.name : ''}`}</title>
			</Helmet>
			<span>{record ? `${record.serial} ` : ''}</span>
		</>
	);
};

const ProductItemActions = (props) => (
	<ShowActions {...props}>
		<BackToStockButton record={props.data} />
		<ProductItemMoveButton record={props.data} />
	</ShowActions>
);

const Tabs = props => (
	<TabbedShowLayout {...props}>
		<Tab label="Summary">
			<TextField label="Id" source="id" />
			<TextField source="serial" />
			<TextField source="buy_sm" label="Supplier Billing Mode" />
			<TextField source="rent_sold" label="VENN Billing Mode" />
			<CustomerReferenceField source="customer_id" />
			<ReferenceField label="Site" source="site_id" reference="sites" link="show" allowEmpty>
				<TextField source="name" />
			</ReferenceField>
			<ReferenceField label="Product ref" source="product_id" reference="device_products" link="show" allowEmpty>
				<TextField source="reference" />
			</ReferenceField>
			<TextField source="product.name" label="Product name" />
			<TextField source="product.brand" label="Brand" />
			<TextField source="product.family" label="Family" />
			<TextField source="product.sub_family" label="Sub Family" />
			<TextField source="product.category" label="Category" />
			<BooleanField source="product.managed" label="Managed" />
			<BooleanField source="product.virtual" label="Virtual" />
			<BooleanField source="product.accessory" label="Accessory" />
			<TextField source="product.description" label="Product Description" />
			<TextField source="power_supply_serial" />
      <TextField source="router_serial" />
      <TextField source="account_number" />
      <TextField source="account_email" />
			<TextField source="notes" />
			<DateField label="Created at" source="created_at" />
			<DateField label="Updated at" source="updated_at" />
			<DateField label="Archived date" source="archived_date" />
			<TextField source="archived_notes" label="Archived notes" />
		</Tab>
		<Tab label="Logs">
			<FilteredLogList />
		</Tab>
	</TabbedShowLayout>
);

const ProductItemShow = (props) => (
	<Show title={<ProductItemTitle />} actions={<ProductItemActions {...props} />} {...props}>
		<Tabs />
	</Show>
);

export default ProductItemShow;
