import React from 'react';
import { TextField, ReferenceField, ReferenceArrayField, NumberField, SimpleShowLayout, ShowButton } from 'react-admin';

import Datagrid from '../common/Datagrid';
import LinkFieldButton from '../common/LinkFieldButton';
import DateField from '../common/fields/DateField';
import BooleanField from '../common/fields/BooleanField';
import StarlinkTerminalRow from '../starlink_terminals/StarlinkTerminalRow';
import StarlinkSubscriptionRow from '../starlink_subscriptions/StarlinkSubscriptionRow';


const StarlinkServiceLineDrawer = (props) => (
    <SimpleShowLayout {...props}>
        <TextField source="id" />
        <TextField source="service_line_number" />
        <ReferenceField label="Starlink account" source="starlink_account" reference="starlink_accounts" link="show" allowEmpty>
            <TextField source="account_number" />
        </ReferenceField>
        <ReferenceField label="Starlink address" source="starlink_address" reference="starlink_addresses" link="show" allowEmpty>
            <TextField source="formatted_address" />
        </ReferenceField>
        <NumberField source="current_usage" label="Current Usage(GB)" sortable={false}/>
        <BooleanField source="active" />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
        <ReferenceArrayField addLabel={false} reference="starlink_terminals" source="starlink_terminals">
            <StarlinkTerminalRow />
        </ReferenceArrayField>
        <ReferenceArrayField addLabel={false} reference="starlink_subscriptions" source="starlink_subscriptions">
            <StarlinkSubscriptionRow />
        </ReferenceArrayField>
    </SimpleShowLayout>
);

const StarlinkServiceLineRow = ({ children, ...props }) => (
    <Datagrid drawer={<StarlinkServiceLineDrawer {...props} />} {...props}>
        <LinkFieldButton label="Service line number" sortBy="service_line_number">
            <TextField source="service_line_number" />
        </LinkFieldButton>
        <TextField source="account_number" />
        <TextField source="nickname" />
        <BooleanField source="is_opted_in" label="Opted In" />
        <TextField source="product_reference_id" label="Product" />
        <DateField source="start_date" />
        <DateField source="end_date" />
        <NumberField source="current_usage" label="Current Usage(GB)" sortable={false} />
        <NumberField source="usage_limit" sortable={false} />
        {children}
        <ShowButton />
    </Datagrid>
);

StarlinkServiceLineRow.defaultProps = {
    basePath: '/starlink_service_lines'
};

export default StarlinkServiceLineRow;