import React from 'react';
import { TextInput, BooleanInput } from 'react-admin';

import GetAppIcon from '@material-ui/icons/GetApp';

import List, { ListActions } from '../common/List';
import Filter from '../common/Filter';
import StarlinkServiceLineRow from './StarlinkServiceLineRow';

import IconButton from '../common/IconButton';

import { API_URL } from '../../services/settings';


const StarlinkServiceLineActions = (props) => (
    <ListActions {...props}>
        <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/starlink_service_lines/export.xls`}>Export</IconButton>
    </ListActions>
);

const StarlinkServiceLineFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <BooleanInput source="active" />
        <BooleanInput source="is_opted_in" />
    </Filter>
);

const StarlinkServiceLineList = props => (
    <List {...props} filters={<StarlinkServiceLineFilter />} actions={<StarlinkServiceLineActions />} exporter={false}>
        <StarlinkServiceLineRow />
    </List>
);

export default StarlinkServiceLineList;
