import React from 'react';
import { useListContext } from 'react-admin';
import Grid from '@material-ui/core/Grid';

import DataServiceCard from './cards/DataServiceCard';
import LineServiceCard from './cards/LineServiceCard';
import SDWANServiceCard from './cards/SDWANServiceCard';
import SDIPServiceCard from './cards/SDIPServiceCard';
import StarLinkServiceCard from './cards/StarLinkServiceCard';
import CustomServiceCard from './cards/CustomServiceCard';
import OneTimeServiceCard from './cards/OneTimeServiceCard';

const ServiceGrid = () => {
    const { ids, data, basePath } = useListContext();

    return (
        <Grid container spacing={3}>
            {ids.map(id => {
                if (data[id].type === 'DATA') {
                    return (
                        <Grid item xs={12} sm={12} md={6} lg={4} key={`service_${id}`}>
                            <DataServiceCard record={data[id]} basePath={basePath} />
                        </Grid>
                    );
                }

                if (data[id].type === 'LINE') {
                    return (
                        <Grid item xs={12} sm={12} md={6} lg={4} key={`service_${id}`}>
                            <LineServiceCard record={data[id]} basePath={basePath} />
                        </Grid>
                    );
                }

                if (data[id].type === 'SDWAN') {
                    return (
                        <Grid item xs={12} sm={12} md={6} lg={4} key={`service_${id}`}>
                            <SDWANServiceCard record={data[id]} basePath={basePath} />
                        </Grid>
                    );
                }

                if (data[id].type === 'SDIP') {
                    return (
                        <Grid item xs={12} sm={12} md={6} lg={4} key={`service_${id}`}>
                            <SDIPServiceCard record={data[id]} basePath={basePath} />
                        </Grid>
                    );
                }

                if (data[id].type === 'STARLINK') {
                    return (
                        <Grid item xs={12} sm={12} md={6} lg={4} key={`service_${id}`}>
                            <StarLinkServiceCard record={data[id]} basePath={basePath} />
                        </Grid>
                    );
                }

                if(data[id].frequency === 'onetime') {
                    return (
                        <Grid item xs={12} sm={12} md={6} lg={4} key={`service_${id}`}>
                            <OneTimeServiceCard record={data[id]} basePath={basePath} />
                         </Grid>
                    );
                }

                if (data[id].type === 'CUSTOM') {
                    return (
                        <Grid item xs={12} sm={12} md={6} lg={4} key={`service_${id}`}>
                            <CustomServiceCard record={data[id]} basePath={basePath} />
                        </Grid>
                    );
                }

                return (
                    <Grid item xs={12} sm={12} md={6} lg={4} key={`service_${id}`}>
                        <CustomServiceCard record={data[id]} basePath={basePath} />
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default ServiceGrid;