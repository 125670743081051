import React, { useState, useEffect } from 'react';

import { ReferenceInput, AutocompleteInput, Query, ListButton, ShowButton, Title, Toolbar, SaveButton, TextInput, required, useQuery, FormDataConsumer, useReference } from 'react-admin';
import { Grid, Card, CardHeader } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { useHistory, useParams } from 'react-router-dom';
import { useFormState, useForm } from 'react-final-form';

import SimpleForm from '../common/SimpleForm';
import Breadcrumb from '../common/Breadcrumb';
import CardActions from '../common/CardActions';
import CustomList from '../common/CustomList';
import rowStyle from '../wans/rowStyle';
import SimReferenceInput from '../simcards/SimReferenceInput';
import ServiceDeliveryRow from './rows/ServiceDeliveryRow';

import services from './index';

import { provisionService } from '../../services/api';


const optionText = choice => (choice && choice.name ? choice.name : '');
const deviceOptionText = choice => `${choice.serial ? choice.serial : ''} / ${choice.ic_group_name ? choice.ic_group_name : ''} / ${choice.name ? choice.name : ''}`;
const lineOptionText = choice => choice.subscription_id ? choice.subscription_id : '';
const deviceProductOptionText = choice => (choice && choice.reference ? `${choice.name} / ${choice.reference}` : '');
const ipOptionText = choice => `${choice && choice.address ? choice.address : ''}`;

const Actions = ({ record, breadcrumbPath }) => (
    <CardActions>
        <Breadcrumb path={breadcrumbPath} />
        <div>
            <ListButton basePath="/services" />
            <ShowButton basePath="/services" record={record} />
        </div>
    </CardActions>
);

const CustomToolbar = ({ onSave, disabled, serviceId, ...props }) => {
    const formState = useFormState();
    const { change } = useForm();
	const customerId = formState?.values?.customer.id;
    const { referenceRecord: customer } = useReference({ reference: 'customers', id: customerId });

	useEffect(() => {
		if (customer?.default_data_limit_contacts) {
			change('data_limit_contacts', customer.default_data_limit_contacts);
            change('data_limit', 10);
		}
	}, [change, customer]);

    const handleClick = async () => {
        if (formState.valid) {
            let data = new FormData();
            data.append("device_id", formState.values.device_id);
            data.append("line_id", formState.values.line_id);
            data.append("ip_id", formState.values.ip_id);
            if (formState.values.ports_map) {
                data.append("ports_map", JSON.stringify(formState.values.ports_map));
            }
            if (formState.values.reason) {
                data.append("reason", formState.values.reason);
            }
            if (formState.values.data_limit) {
                data.append("data_limit", formState.values.data_limit);
            }
            if (formState.values.data_limit_contacts) {
                data.append("data_limit_contacts", formState.values.data_limit_contacts);
            }
            if (formState.values.notes) {
                data.append("notes", formState.values.notes);
            }

            try {
                const response = await provisionService(serviceId, data);
                if (response.id && onSave) {
                    onSave();
                }
            }
            catch (e) {
                console.error(e);
            }
        }
    };

    return (
        <Toolbar {...props}>
            <SaveButton handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} disabled={disabled} />
        </Toolbar>
    );
};

const ServiceProvision = () => {
    const history = useHistory();
    const { service_id } = useParams();
    const id = service_id;
    const [device_id, setDevice_id] = useState();
    const [deviceData, setDeviceData ] = useState();

    const handleChangeDevice = (id) => {
		setDevice_id(id);
	};

    const handleSave = () => {
        history.push(`/services/${id}/show`);;
    }

    const { data } = useQuery({
		type: 'getOne',
		resource: 'devices',
		payload: { id: device_id, data: { sync: false }}
	});

    useEffect(() => {
        if (data) {
            setDeviceData(data);
        }
    }, [data]);

    const renderPorts = (device) => {

		if (!device || !device.ports_map) {
			return;
		}

		const { ports_map } = device;

		return (
			<Grid container spacing={3} fullWidth>
				{Object.keys(ports_map).map((key) => (
					<Grid item xs={6} key={key}>
						<SimReferenceInput label={key} source={`ports_map[${key}]`} resource="simcards" reference="simcards" variant="outlined" />
					</Grid>
				))}
			</Grid>
		);
	};

	const renderIcSims = (device) => {
		if(!device || !device.ic_sim_cards) {
			return;
		}

		return (
			<Table fullWidth>
				<TableHead>
					<TableRow>
						<TableCell>Port</TableCell>
						<TableCell>ICCID</TableCell>
						<TableCell>Wan name</TableCell>
						<TableCell>Wan IC_ID</TableCell>
						<TableCell>Status</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{device.ic_sim_cards.map(sim => {
						const wan = device.wans.find(w => w.id === sim.wan);

						return (
							<TableRow key={`sim_${sim.id}`} style={rowStyle(wan)}>
								<TableCell>{sim.device_port_name}</TableCell>
								<TableCell>{sim.iccid}</TableCell>
								<TableCell>{wan.name}</TableCell>
								<TableCell>{wan.ic_id}</TableCell>
								<TableCell>{wan.ic_status}</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		);
	};

    return (
        <Query type="GET_ONE" resource="services" payload={{ id: id }}>
            {({ data }) => {
                if (!data) {
                    return null;
                }

                const type = data?.type?.toUpperCase();
                const disabled = data.status !== 'PROVISIONING';
                const breadcrumbPath = [
                    { url: '/services', title: "Services", icon: <services.icon /> },
                    {
                        url: `/services/${data.id}/show`,
                        title: (data ? data.name : data.id)
                    },
                    {
                        url: `/services/${data.id}/provision`,
                        title: 'Provision',
                        isActive: true
                    }
                ];

                return (
                    <>
                        <Title title="Provision" />
                        <CardContent>
                            <Actions record={data} breadcrumbPath={breadcrumbPath} />
                        </CardContent>
                        {['SDWAN'].includes(type) && (
                            <Card>
                                <SimpleForm redirect="show" record={data} resource="services" toolbar={<CustomToolbar onSave={handleSave} disabled={disabled} serviceId={id} />} initialValues={{ data_limit: 10 }}>
                                    <ReferenceInput label="Customer" source="customer.id" reference="customers" sort={{ field: 'name', order: 'ASC' }} disabled>
                                        <AutocompleteInput optionText={optionText} />
                                    </ReferenceInput>
                                    <ReferenceInput label="Site" source="site_id" reference="sites" sort={{ field: 'name', order: 'ASC' }} disabled>
                                        <AutocompleteInput optionText={optionText} />
                                    </ReferenceInput>
                                    <ReferenceInput label="Product" source="product_id" reference="device_products" filter={{ managed: true }} sort={{ field: 'name', order: 'ASC' }} allowEmpty>
                                        <AutocompleteInput optionText={deviceProductOptionText} />
                                    </ReferenceInput>

                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => (
                                            <ReferenceInput {...rest} label="Device" source="device_id" reference="devices" filter={formData.product_id ? { in_stock: true, product_id: formData.product_id} : { in_stock: true }} validate={required()} onChange={handleChangeDevice}>
                                                <AutocompleteInput optionText={deviceOptionText} />
                                            </ReferenceInput>
                                        )}
                                    </FormDataConsumer>
                                    <TextInput label="Device Notes" source="notes" options={{ multiline: true }} rows="4" />
				                    <TextInput label="Data Limit Alert (GB)" source="data_limit" />
				                    <TextInput label="Data Limit Contacts (CSV)" source="data_limit_contacts" options={{ multiline: true }} rows="4"/>
				                    {deviceData && renderPorts(deviceData)}
				                    {deviceData && renderIcSims(deviceData)}
                                </SimpleForm>
                            </Card>
                        )}

                        {['SDIP'].includes(type) && (
                            <Card>
                                <SimpleForm redirect="show" record={data} resource="services" toolbar={<CustomToolbar onSave={handleSave} disabled={disabled} serviceId={id} />}>
                                    <ReferenceInput label="Customer" source="customer.id" reference="customers" sort={{ field: 'name', order: 'ASC' }} disabled>
                                        <AutocompleteInput optionText={optionText} />
                                    </ReferenceInput>
                                    <ReferenceInput label="Site" source="site_id" reference="sites" sort={{ field: 'name', order: 'ASC' }} disabled>
                                        <AutocompleteInput optionText={optionText} />
                                    </ReferenceInput>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => (
                                            <ReferenceInput {...rest} label="IP" source="ip_id" reference="ips" filter={{ available: true }} validate={required()} onChange={handleChangeDevice}>
                                                <AutocompleteInput optionText={ipOptionText} />
                                            </ReferenceInput>
                                        )}
                                    </FormDataConsumer>
                                </SimpleForm>
                            </Card>
                        )}

                        {['STARLINK'].includes(type) && (
                            <Card>
                                <SimpleForm redirect="show" record={data} resource="services" toolbar={<CustomToolbar onSave={handleSave} disabled={disabled} serviceId={id} />}>
                                    <ReferenceInput label="Customer" source="customer.id" reference="customers" sort={{ field: 'name', order: 'ASC' }} disabled>
                                        <AutocompleteInput optionText={optionText} />
                                    </ReferenceInput>
                                    <ReferenceInput label="Site" source="site_id" reference="sites" sort={{ field: 'name', order: 'ASC' }} disabled>
                                        <AutocompleteInput optionText={optionText} />
                                    </ReferenceInput>
                                    <ReferenceInput label="Line" source="line_id" reference="lines" sort={{ field: 'subscription_id', order: 'ASC' }} filter={{ line_type: 'vsat' }}>
                                        <AutocompleteInput optionText={lineOptionText} />
                                    </ReferenceInput>             
                                </SimpleForm>
                            </Card>
                        )}


                        {['LINE'].includes(type) && (
                            <Card>
                                <SimpleForm redirect="show" record={data} resource="services" toolbar={<CustomToolbar onSave={handleSave} disabled={disabled} serviceId={id} />}>
                                    <ReferenceInput label="Customer" source="customer.id" reference="customers" sort={{ field: 'name', order: 'ASC' }} disabled>
                                        <AutocompleteInput optionText={optionText} />
                                    </ReferenceInput>
                                    <ReferenceInput label="Site" source="site_id" reference="sites" sort={{ field: 'name', order: 'ASC' }} disabled>
                                        <AutocompleteInput optionText={optionText} />
                                    </ReferenceInput>
                                    <ReferenceInput label="Line" source="line_id" reference="lines" sort={{ field: 'subscription_id', order: 'ASC' }} filter={{ customer_id: data.customer_id }}>
                                        <AutocompleteInput optionText={lineOptionText} />
                                    </ReferenceInput>
                                </SimpleForm>
                            </Card>
                        )}
                        <Card>
                        <CardHeader title={`In same package`} />
                        <CustomList
                            resource="services"
                            sort={{ field: 'status', order: 'DESC' }}
                            filter={{ package_id: data.package_id }}
                            basePath="/services"
                            filters={null}
                            bulkActionButtons={false}
                            exporter={false}
                            perPage={20}
                            pagination={false}
                            actions={false}
                        >
                            <ServiceDeliveryRow exclude={["customer"]} />
                        </CustomList>
                    </Card>
                    </>
                );
            }}
        </Query>
    );
};

export default ServiceProvision;