import React from 'react';
import ProductItemBulkMoveButton from './ProductItemBulkMoveButton';
import ProductItemBulkArchiveButton from './ProductItemBulkArchiveButton';
import ProductItemBulkReserveButton from './ProductItemBulkReserveButton';

const ProductItemBulkActions = (props) => (
    <>
        <ProductItemBulkReserveButton  {...props} />
        <ProductItemBulkMoveButton {...props} />
        <ProductItemBulkArchiveButton {...props} />
    </>
);

export default ProductItemBulkActions;