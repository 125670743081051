import React from 'react';
import { AutocompleteInput } from 'react-admin';

const choices = [
    { id: '00D', name: '00D' },
    { id: '10D', name: '10D' },
    { id: '30D', name: '30D' },
    { id: '45D', name: '45D' },
    { id: '60D', name: '60D' },
];

const PaymentTermsInput = ({ classes, record, source = "payment_terms", ...props }) => {
    if (record && record[source] && !choices.find((provider) => provider.id === record[source])) {
        choices.push({ id: record[source], name: record[source] });
    }

    return (
        <AutocompleteInput choices={choices} source={source} record={record} allowEmpty {...props} />
    );
};

export default PaymentTermsInput;
