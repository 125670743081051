import React from 'react';
import { Query, ListButton, ShowButton, Title, Toolbar, SaveButton, TextInput, NumberInput, ReferenceInput, AutocompleteInput, useNotify } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { useHistory, useParams } from 'react-router-dom';
import { useFormState } from 'react-final-form';

import SimpleForm from '../common/SimpleForm';
import Breadcrumb from '../common/Breadcrumb';
import CardActions from '../common/CardActions';

import services from './index';

import { provisionPackageLine } from '../../services/api';

const optionText = choice => (choice && choice.name ? choice.name : '');

const Actions = ({ record, breadcrumbPath }) => (
    <CardActions>
        <Breadcrumb path={breadcrumbPath} />
        <div>
            <ListButton basePath="/package_lines" />
            <ShowButton basePath="/package_lines" record={record} />
        </div>
    </CardActions>
);

const CustomToolbar = ({ onSave, disabled, packageLineId, ...props }) => {
    const formState = useFormState();
    const notify = useNotify();

    const handleClick = async () => {
        if (formState.valid) {
            let data = new FormData();
            data.append("serials", formState.values.serials);
            try {
                const response = await provisionPackageLine(packageLineId, data);
                if (response.id && onSave) {
                    onSave();
                }
                if (!response.success) {
                    notify(response.message, 'error');
                }
            }
            catch (e) {
                console.error(e);
            }
        }
    };

    return (
        <Toolbar {...props}>
            <SaveButton handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} disabled={disabled} />
        </Toolbar>
    );
};

const PackageLineProvision = () => {
    const history = useHistory();
    const { package_line_id } = useParams();
    const id = package_line_id;

    const handleSave = () => {
        history.push(`/package_lines/${id}/show`);;
    }

    return (
        <Query type="GET_ONE" resource="package_lines" payload={{ id: id }}>
            {({ data }) => {
                if (!data) {
                    return null;
                }

                // const type = data?.type?.toUpperCase();
                const disabled = data.status !== 'PROVISIONING';
                const breadcrumbPath = [
                    { url: '/package_lines', title: "Packages Lines", icon: <services.icon /> },
                    {
                        url: `/package_lines/${data.id}/show`,
                        title: (data ? data.name : data.id)
                    },
                    {
                        url: `/package_lines/${data.id}/provision`,
                        title: 'Provision',
                        isActive: true
                    }
                ];

                return (
                    <>
                        <Title title="Provision" />
                        <CardContent>
                            <Actions record={data} breadcrumbPath={breadcrumbPath} />
                        </CardContent>

                        <Card>
                            <SimpleForm redirect="show" record={data} resource="services" toolbar={<CustomToolbar onSave={handleSave} disabled={disabled} packageLineId={id} />}>
                                <ReferenceInput label="Site" source="site_id" reference="sites" sort={{ field: 'name', order: 'ASC' }} disabled >
                                    <AutocompleteInput optionText={optionText} />
                                </ReferenceInput>
                                <ReferenceInput label="Product" source="device_product_id" reference="device_products" disabled >
                                    <AutocompleteInput optionText={optionText} />
                                </ReferenceInput>
                                <ReferenceInput label="Service Attached" source="service_id" reference="services" disabled >
                                    <AutocompleteInput optionText={optionText} />
                                </ReferenceInput>
                                <NumberInput source="qty" disabled />
                                <TextInput label="Serials" source="serials" options={{ multiline: true }} rows="4" />
                            </SimpleForm>
                        </Card>
                    </>
                );
            }}
        </Query>
    );
};

export default PackageLineProvision;