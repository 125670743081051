import React from 'react';

import ExportPDFButton from './ExportPDFButton';
import SendInvoiceButton from './SendInvoiceButton';
import ReverseInvoiceButton from './ReverseInvoiceButton';
import PaidInvoiceButton from './PaidInvoiceButton';

const InvoiceActionsButtons = (props) => (
    <>
        <ExportPDFButton {...props} />
        <SendInvoiceButton {...props} />
        <ReverseInvoiceButton {...props} />
        <PaidInvoiceButton {...props} />
    </>
);

export default InvoiceActionsButtons;