import React, { useState } from 'react';
import { Confirm, useRefresh, useNotify } from 'react-admin';
import CheckIcon from '@material-ui/icons/Check';

import IconButton from '../../common/IconButton';

import { markPaidInvoice } from '../../../services/api';


const PaidInvoiceButton = ({ record, variant, color }) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const [showDialog, setShowDialog] = useState(false);

    const handleConfirm = async () => {
        const response = await markPaidInvoice(record.id);
        if (response.id) {
            refresh();
            notify('Invoice marked as paid');
        }

        handleClose();
    };

    const handleOpen = () => {
        setShowDialog(true);
    };

    const handleClose = () => {
        setShowDialog(false);
    };

    if (!record || record.status === 'draft') {
        return null;
    }

    return (
        <>
            <IconButton icon={<CheckIcon />} onClick={handleOpen} variant={variant} color={color}>Mark Paid</IconButton>
            <Confirm
                isOpen={showDialog}
                title={`Mark paid invoice or credit #${record.id}`}
                content={`Are you sure you want to mark as paid this document ?`}
                onConfirm={handleConfirm}
                onClose={handleClose}
            />
        </>
    );
};

export default PaidInvoiceButton;
