import React from 'react';
import { TextInput, NumberInput, DateInput, ReferenceInput, AutocompleteInput, FormDataConsumer } from 'react-admin';
import Box from '@material-ui/core/Box';

import Edit from '../common/Edit';
import SimpleForm from '../common/SimpleForm';
import CurrencyInput from '../common/inputs/CurrencyInput';

const optionText = choice => choice && choice.name ? choice.name : '';
const salesOrderOptionText = choice => choice && choice.reference ? choice.reference : '';
const lineOptionText = choice => choice.subscription_id ? choice.subscription_id : '';
const deviceOptionText = choice => `${choice.serial ? choice.serial : ''} / ${choice.ic_group_name ? choice.ic_group_name : ''} / ${choice.name ? choice.name : ''}`;
const ipOptionText = choice => `${choice.address ? choice.address : ''}`;
const userOptionText = choice => choice && choice.email ? choice.email : '';

const ServiceEdit = props => (
    <Edit {...props}>
        <SimpleForm redirect="show">
            <ReferenceInput label="Assigned To" source="assignee_id" reference="users" filter={{ is_staff: true }} sort={{ field: 'name', order: 'ASC' }} allowEmpty >
                <AutocompleteInput optionText={userOptionText} />
            </ReferenceInput>
            <TextInput source="id" disabled />
            <TextInput source="type" disabled />
            <TextInput source="reference" disabled />
            <ReferenceInput label="Package" source="package_id" reference="packages" sort={{ field: 'reference', order: 'ASC' }}>
                <AutocompleteInput optionText={salesOrderOptionText} />
            </ReferenceInput>
            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    <ReferenceInput
                        label="Product"
                        source="product_id"
                        reference="service_products"
                        sort={{ field: 'name', order: 'ASC' }}
                        filter={formData.type && { service_type: formData.type }}
                        {...rest}
                    >
                        <AutocompleteInput optionText={optionText} />
                    </ReferenceInput>
                )}
            </FormDataConsumer>
            <NumberInput source="price" />
            <FormDataConsumer>
                {({ formData, ...rest }) => formData.type === 'SDWAN' &&
                    <>
                        <ReferenceInput label="Device" source="device_id" reference="devices" sort={{ field: 'serial', order: 'ASC' }} {...rest} allowEmpty>
                            <AutocompleteInput optionText={deviceOptionText} />
                        </ReferenceInput>
                        <ReferenceInput label="Data pool" source="data_pool_id" reference="data_pools" sort={{ field: 'reference', order: 'ASC' }} {...rest} allowEmpty>
                            <AutocompleteInput optionText={optionText} />
                        </ReferenceInput>
                        <ReferenceInput label="Sold Hardware" source="package_line_id" reference="package_lines" sort={{ field: 'id', order: 'ASC' }} filter={formData.package_id && { package_id: formData.package_id }} {...rest} allowEmpty>
                            <AutocompleteInput optionText={optionText} />
                        </ReferenceInput>
                    </>
                }
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData, ...rest }) => formData.type === 'SDIP' &&
                    <>
                        <ReferenceInput label="IP" source="ip_id" reference="ips" sort={{ field: 'address', order: 'ASC' }} {...rest} allowEmpty>
                            <AutocompleteInput optionText={ipOptionText} />
                        </ReferenceInput>
                    </>
                }
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData, ...rest }) => (formData.type === 'STARLINK' || formData.type === 'LINE') &&
                    <ReferenceInput label="Line" source="line_id" reference="lines" sort={{ field: 'subscription_id', order: 'ASC' }} {...rest} allowEmpty>
                        <AutocompleteInput optionText={lineOptionText} />
                    </ReferenceInput>
                }
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData, ...rest }) => formData.type === 'DATA' &&
                    <>
                        <NumberInput label="Data Included (GB)" source="data_included" {...rest} />
                        <NumberInput source="data_extra_price"{...rest} />
                    </>
                }
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData, ...rest }) => (formData.type === 'CUSTOM') &&
                    <>
                        <TextInput label="Serial" source="serial" {...rest} />
                    </>
                }
            </FormDataConsumer>
            <DateInput source="start_date" />
            <DateInput source="end_date" />
            <DateInput source="early_end_date" label="Commitment date" />
            <FormDataConsumer>
                {({ formData, ...rest }) => (formData.type === 'STARLINK' || formData.type === 'DATA') &&
                    <>
                        <Box>
                            <NumberInput source="data_limit" label="Data Limit Alert (GB)" {...rest} />
                        </Box>
                        <Box>
                            <TextInput source="data_limit_contacts" options={{ multiline: true }} rows="4" placeholder="john.doe@acme.com; jane.doe@acme.com" {...rest} />
                        </Box>
                    </>
                }
            </FormDataConsumer>
            <TextInput source="notes" label="Notes" options={{ multiline: true }} rows="4" />
            <TextInput source="shipping_tracking_code" />
            <DateInput source="shipping_date" />
            <DateInput source="delivery_date" />
        </SimpleForm>
    </Edit>
);

export default ServiceEdit;