import React from 'react';
import { TextInput, BooleanInput, ReferenceInput, AutocompleteInput, useListContext } from 'react-admin';
import GetAppIcon from '@material-ui/icons/GetApp';

import List, { ListActions, BulkActions } from '../common/List';
import Filter from '../common/Filter';
import PackageLineRow from './PackageLineRow';
import IconButton from '../common/IconButton';
import PackageLineStatusField from './PackageLineStatusField';
import PackageLineQueueField from './PackageLineQueueField';
import PackageLineBulkShip from './buttons/PackageLineBulkShip';
import PackageLineBulkDeliver from './buttons/PackageLineBulkDeliver';

import { API_URL } from '../../services/settings';


const packageOptionText = choice => choice.reference ? choice.reference : '';

const customerOptionText = choice => {
    return choice.name ? choice.name : '';
};

const siteOptionText = choice => {
    return choice.name ? choice.name : '';
};


const PackageLineActions = (props) => (
    <ListActions {...props}>
        <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/package_lines/export.xls`}>Export</IconButton>
    </ListActions>
);

const PackageLineFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="Package" source="package_id" reference="packages" sort={{ field: 'reference', order: 'ASC' }} allowEmpty alwaysOn>
            <AutocompleteInput optionText={packageOptionText} />
        </ReferenceInput>
        <PackageLineStatusField label="Status" source="status" alwaysOn />
        <ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} variant="outlined" alwaysOn>
            <AutocompleteInput optionText={customerOptionText} />
        </ReferenceInput>
        <ReferenceInput label="Site" source="site_id" reference="sites" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
            <AutocompleteInput optionText={siteOptionText} />
        </ReferenceInput>
        <BooleanInput label="Not Invoiced" source="not_invoiced" alwaysOn />
        <PackageLineQueueField label="Queue" source="queue" />
    </Filter>
);

const filterDefaultValues = {
    invoiced: false,
}

const PackageLineBulkActionsButtons = (props) => {
    const { filterValues } = useListContext();
    const status = filterValues.status

    if (status === 'provisioned') {
        return (<PackageLineBulkShip {...props} />);
    } else if (status === 'shipped') {
        return (<PackageLineBulkDeliver {...props} />); 
    } else {
        return (<BulkActions {...props} />);
    }
};

const PackageLineListComponent = (props) => {
    const { filterValues } = useListContext();
    const isForDelivery = filterValues.queue !== undefined && filterValues.queue !== '';
    const status = filterValues.status;

    // To be refactored, can be improved by handling multiple status

    if (isForDelivery && status === 'provisioning') {
        return (<PackageLineRow {...props} exclude={['serials', 'shipping_tracking_code', 'tracking_url', 'shipping_date', 'delivery_date']}/>);
    }

    return (
        <>
            {isForDelivery ? (
                <PackageLineRow {...props} exclude={['serials', 'price', 'total', 'invoiced']} />
            ) : (
                <PackageLineRow {...props} exclude={['serials', 'shipping_tracking_code']} />
            )}</>
    )
};


const PackageLineList = props => (
	<List {...props} filters={<PackageLineFilter/>} actions={<PackageLineActions />} filterDefaultValues={ filterDefaultValues } perPage={50} exporter={false} bulkActionButtons={<PackageLineBulkActionsButtons />}>
		<PackageLineListComponent />
	</List>
);

export default PackageLineList;
