import React from 'react';
import { Tab, TextField, ReferenceField } from 'react-admin';

import DateField from '../../common/fields/DateField';
import NumberCurrencyField from '../../common/fields/NumberCurrencyField';
import { ShowActions } from '../../common/Show';
import ServiceProvisionButton from '../ServiceActionsButtons/ServiceProvisionButton';

const SDIPServiceActions = (props) => (
    <ShowActions {...props}>
        <ServiceProvisionButton record={props.data} />
    </ShowActions>
);

const SDIPServiceShow = (props) => (
    <Tab label="summary" {...props}>
        <TextField source="status" />
        <TextField source="type" />
        <ReferenceField label="Assignee" source="assignee_id" reference="users" allowEmpty>
            <TextField source="email" />
        </ReferenceField>
        <ReferenceField label="Package" source="package_id" reference="packages" link="show" allowEmpty>
            <TextField source="reference" />
        </ReferenceField>
        <TextField source="reference" />
        <TextField source="customer_reference"/>
        <ReferenceField label="Last Invoice" source="last_invoice_id" reference="invoices" link="show" allowEmpty>
            <TextField source="id" />
        </ReferenceField>
        <TextField source="name" />
        <TextField source="frequency" />
        <ReferenceField label="Site" source="site_id" reference="sites" link="show" allowEmpty>
            <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="IP" source="ip_id" reference="ips" link="show" allowEmpty>
            <TextField source="address" />
        </ReferenceField>
        <NumberCurrencyField source="price" />
        <DateField source="start_date" />
        <DateField source="end_date" />
        <TextField source="end_reason" />
        <DateField source="early_end_date" label="Commitment date" />
        <TextField source="notes" options={{ multiline: true }} rows="4" />
        <DateField label="Created at" source="created_at" />
        <DateField label="Updated at" source="updated_at" />
    </Tab>
);

export default SDIPServiceShow;
export { SDIPServiceActions };
