import React from 'react';
import { ReferenceArrayField } from 'react-admin';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import ServiceGrid from '../services/ServiceGrid';

const ServiceGridList = ({ record }) => {
	let list = [];

	if (!record) {
		return null;
	}

	if (record.services_by_type?.sdwan) {
		list.push(
			<Box mb={5} key="services_sdwan">
				<Typography variant="h6">SDWan</Typography>
				<ReferenceArrayField addLabel={false} reference="services" record={record} source="services_by_type.sdwan">
					<ServiceGrid />
				</ReferenceArrayField>
			</Box>
		);
	}
	if (record.services_by_type?.sdip) {
		list.push(
			<Box mb={5} key="services_sdip">
				<Typography variant="h6">SDIP</Typography>
				<ReferenceArrayField addLabel={false} reference="services" record={record} source="services_by_type.sdip">
					<ServiceGrid />
				</ReferenceArrayField>
			</Box>
		);
	}
	if (record.services_by_type?.data) {
		list.push(
			<Box mb={5} key="services_data">
				<Typography variant="h6">Data</Typography>
				<ReferenceArrayField addLabel={false} reference="services" record={record} source="services_by_type.data">
					<ServiceGrid />
				</ReferenceArrayField>
			</Box>
		);
	}
	if (record.services_by_type?.line) {
		list.push(
			<Box mb={5} key="services_line">
				<Typography variant="h6">Line</Typography>
				<ReferenceArrayField addLabel={false} reference="services" record={record} source="services_by_type.line">
					<ServiceGrid />
				</ReferenceArrayField>
			</Box>
		);
	}
	if (record.services_by_type?.starlink) {
		list.push(
			<Box mb={5} key="services_starlink">
				<Typography variant="h6">StarLink</Typography>
				<ReferenceArrayField addLabel={false} reference="services" record={record} source="services_by_type.starlink">
					<ServiceGrid />
				</ReferenceArrayField>
			</Box>
		);
	}
	if (record.services_by_type?.custom) {
		list.push(
			<Box mb={5} key="services_custom">
				<Typography variant="h6">Custom</Typography>
				<ReferenceArrayField addLabel={false} reference="services" record={record} source="services_by_type.custom">
					<ServiceGrid />
				</ReferenceArrayField>
			</Box>
		);
	}
	if (record.services_by_type?.onetime) {
		list.push(
			<Box mb={5} key="services_custom">
				<Typography variant="h6">One Time</Typography>
				<ReferenceArrayField addLabel={false} reference="services" record={record} source="services_by_type.onetime">
					<ServiceGrid />
				</ReferenceArrayField>
			</Box>
		);
	}

	return (
		<Box>
			{list}
		</Box>
	);
};

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					{children}
				</Box>
			)}
		</div>
	);
}

const PackageServiceGridList = (props) => {
	const [value, setValue] = React.useState(0);
	const records = props?.data ? Object.values(props.data) : [];

	if (!records || records.length === 0) {
		return null;
	}

	const handleChange = (e, newValue) => {
		setValue(newValue);
	};

	return (
		<>
			<Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
				{records.map((record) => (
					<Tab label={`${record.reference} - ${record.customer_reference}`} key={`tab_package_${record.id}`} />
				))}
			</Tabs>

			{records.map((record, index) => (
				<TabPanel value={value} index={index} key={`tabpanel_package_${record.id}`}>
					<ServiceGridList record={record} />
				</TabPanel>
			))}
		</>
	);
};

export default PackageServiceGridList;
