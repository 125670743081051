import React from 'react';
import { TextInput, NumberInput, AutocompleteInput, ReferenceInput } from 'react-admin';

import Edit from '../common/Edit';
import SimpleForm from '../common/SimpleForm';
import VATCodeInput from '../common/inputs/VATCodeInput';
import ServiceTypeField from '../services/ServiceTypeField';


const optionText = choice => `${choice && choice.reference ? choice.reference : ''} / ${choice.name ? choice.name : ''}`;

const redirect = (basePath, id, data, location) => {
    if (data?.invoice_id && location?.state?.record?.invoice_id === data.invoice_id) {
        return `/invoices/${data.invoice_id}/show`;
    }
    return basePath;
};

const InvoiceLineEdit = props => (
    <Edit {...props}>
        <SimpleForm redirect={(basePath, id, data) => redirect(basePath, id, data, props.location)}>
            <ServiceTypeField label="Type" source="type" />
            <TextInput label="Service Product Name" source='product_name' />
            <TextInput label="Service Product Reference" source='product_reference' />
			<TextInput label="PID" source='package_reference' />
            <TextInput label="SID" source='reference' />
			<TextInput label="Customer Reference" source='customer_reference' />
			<TextInput label="Description" source='description' options={{ multiline: true }} />
			<NumberInput label="Quantity" source='qty' />
			<TextInput label="Price" source='unit_price' />
            <VATCodeInput source="vat_code" label="VAT" />
			<TextInput label="start_date" source='start_date' />
			<TextInput label="end_date" source='end_date' />
        </SimpleForm>
    </Edit>
);

export default InvoiceLineEdit;