import React, { useState } from 'react';
import { useRefresh, useNotify, useUnselectAll, SaveButton as RASaveButton } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import LockIcon from '@material-ui/icons/Lock';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import IconButton from '../../../common/IconButton';
import SimpleForm from '../../../common/SimpleForm';
import Toolbar from '../../../common/Toolbar';

import { reserveProductItem } from '../../../../services/api';


const SaveButton = ({ onClick, selectedIds, ...props }) => {
    const formState = useFormState();
    const refresh = useRefresh();
    const form = useForm();
    const notify = useNotify();
    const unselectAll = useUnselectAll();

    const handleClick = async () => {
        let formData = new FormData();
        let successCount = 0;
        let errorCount = 0;
        await Promise.all(
            selectedIds.map(async id => {
                const response = await reserveProductItem(id, formData);
                if (response.success || response.id) {
                    successCount++;
                }
                else {
                    errorCount++;
                }
            })
        );

        if (successCount) {
            notify(`${successCount} item(s) reserved`);
        }
        if (errorCount) {
            notify(`${errorCount} item(s) reservations failed`, 'warning');
        }

        refresh();
        unselectAll('product_items');
        form.reset();
        onClick();
    };

    return <RASaveButton {...props} label="OK" handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} />;
};

const ProductItemBulkReserveButton = ({ variant, color, ...props }) => {
    const [showDialog, setShowDialog] = useState(false);

    const handleSave = () => {
        handleClose();
    };

    const handleOpen = () => {
        setShowDialog(true);
    };

    const handleClose = () => {
        setShowDialog(false);
    };

    return (
        <>
            <IconButton onClick={handleOpen} icon={<LockIcon />} color={color} variant={variant}>Reserve</IconButton>
            <Dialog fullWidth open={showDialog} onClose={handleClose} aria-label="Reserve Product Item">
                <DialogTitle>Lock {props.selectedIds.length} item(s)</DialogTitle>
                <DialogContent>
                    {`Are you sure you want to reserve these ${props.selectedIds.length} items(s)?`}
                    <SimpleForm toolbar={<Toolbar onClose={handleClose} onSave={handleSave} saveButton={<SaveButton onClick={handleSave} selectedIds={props.selectedIds} />} />}>
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ProductItemBulkReserveButton;