import React, { useState } from 'react';
import {
	useMutation,
	useRefresh,
	useNotify,
	SaveButton as RASaveButton,
	TextInput,
	DateInput,
	RadioButtonGroupInput
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { useForm, useFormState } from 'react-final-form';
import IconUndo from '@material-ui/icons/Undo';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';

import IconButton from '../../common/IconButton';
import SimpleForm from '../../common/SimpleForm';
import Toolbar from './Toolbar';
import ServiceEndReasonInput from '../ServiceEndReasonInput';
import { endService } from '../../../services/api';



const useStyles = makeStyles(theme => ({
	suggestionsContainerOpen: {
		position: 'absolute',
		marginBottom: theme.spacing(3),
		zIndex: theme.zIndex.modal + 200,
	}
}));

const SaveButton = ({ onClick, record, ...props }) => {
	const formState = useFormState();
	const refresh = useRefresh();
	const form = useForm();

	const handleClick = async () => {
		let formData = new FormData();
		formData.append("end_date", new Date(formState.values.end_date).toISOString());
		formData.append("end_reason", formState.values.end_reason)
		await endService(record.id, formData);
		form.reset();
		onClick();
		refresh();
	};
	return <RASaveButton {...props} handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} />;
};

const ServiceEndButton = ({ record, resource, label, variant, color }) => {
	const refresh = useRefresh();
	const notify = useNotify();
	const [showDialog, setShowDialog] = useState(false);
	const classes = useStyles();

	const handleSave = () => {
		handleClose();
	};

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	return (
		!record.start_date || record.end_date ? (
			<></>
		) : (
			<>
				<IconButton icon={<DeleteIcon />} onClick={handleOpen} color="secondary" variant={variant}>{label}</IconButton>
				<Dialog fullWidth open={showDialog} onClose={handleClose} aria-label="End Service">
					<DialogTitle>End Service</DialogTitle>
					<DialogContent>
							Are you sure you want to end this service ?
						<SimpleForm toolbar={<Toolbar onClose={handleClose} onSave={handleSave} saveButton={<SaveButton onClick={handleSave} />} />} record={{ ...record, end_date: new Date() }}>
							<ServiceEndReasonInput
								options={{ suggestionsContainerProps: { className: classes.suggestionsContainerOpen } }}
								label="Reason"
							/>
							<DateInput source="end_date" label="End Date" />
						</SimpleForm>
					</DialogContent>
				</Dialog>
			</>
		)
	);
};

ServiceEndButton.defaultProps = {
	label: 'Terminate'
};

export default ServiceEndButton;