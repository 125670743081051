import React from 'react';
import { Labeled } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	link: {
		textDecoration: 'none'
	}
});

const StarlinkDashboardUrlField = ({ record }) => {
    const classes = useStyles();
    return (
        <Labeled label="Starlink URL">
            <a href={`https://www.starlink.com/account/dashboard/service-line/${record.service_line_number}`} target="__blank" className={classes.link}>Open Starlink</a>
        </Labeled>
    );
};

export default StarlinkDashboardUrlField;