import React, { useEffect, useState } from 'react';
import { TextInput } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useFormState, useForm } from 'react-final-form';
import { compose, withProps } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

import { GOOGLE_MAP_API_KEY } from '../../services/settings';

import { getLatLngFromAddress } from '../../services/api';

const GoogleMapComponent = compose(
	withProps(props => {
		return {
			googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
			loadingElement: <div style={{ height: `100%` }} />,
			containerElement: <div style={{ height: props.height || `260px` }} />,
			mapElement: <div style={{ height: `100%` }} />,
			defaultZoom: 16
		}
	}),
	withScriptjs,
	withGoogleMap
)(({ record = {}, source, defaultZoom, lat, lng, onClick, ...props }) => (
	<GoogleMap
		defaultZoom={defaultZoom}
		defaultCenter={{ lat, lng }}
		center={{ lat, lng }}
		onClick={onClick}
		{...props}
	>
		{(lat && lng) && <Marker position={{ lat, lng }} />}
	</GoogleMap>
));

const useStyles = makeStyles({
	gridItem: {
		paddingTop: '0 !important',
		paddingBottom: '0 !important'
	},
	gridMap: {
		width: '100%',
		paddingLeft: '12px'
	}
});

const SiteAddressInputsMap = (props) => {
	const { record } = props;
	const [address, setAddress] = useState({
		street: record.street,
		number: record.number,
		zipcode: record.zipcode,
		city: record.city,
		country: record.country
	});
	const { values } = useFormState();
	const form = useForm();
	const classes = useStyles();

	useEffect(() => {
		const timerId = setTimeout(async () => {
			if ((values?.street && values?.number && values?.zipcode && values?.city && values?.country) &&
			(values.street !== address?.street || values.number !== address?.number || values.zipcode !== address?.zipcode || values.city !== address?.city || values.country !== address?.country)) {
				setAddress({
					street: values.street,
					number: values.number,
					zipcode: values.zipcode,
					city: values.city,
					country: values.country
				});
				const addressStr = `${values.street} ${values.number} ${values.zipcode} ${values.city} ${values.country}`;
				const response = await getLatLngFromAddress(addressStr);
				if (response?.lat && response?.lng) {
					form.change('lat', response.lat);
					form.change('lng', response.lng);
				}
			}
		}, 2000);

		return () => {
			clearTimeout(timerId);
		};
	}, [values.street, values.number, values.zipcode, values.city, values.country]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleClick = (e) => {
		if (e?.latLng?.lat() && e?.latLng?.lng()) {
			form.change('lat', e.latLng.lat());
			form.change('lng', e.latLng.lng());
		}
	};

	return (
		<Grid container>
			<Grid item md={6}>
				<Grid container spacing={3}>
					<Grid item xs={9} className={classes.gridItem}>
						<TextInput {...props} source="street" fullWidth />
					</Grid>
					<Grid item xs={3} className={classes.gridItem}>
						<TextInput {...props} source="number" fullWidth />
					</Grid>
					<Grid item xs={3} className={classes.gridItem}>
						<TextInput {...props} source="zipcode" fullWidth />
					</Grid>
					<Grid item xs={9} className={classes.gridItem}>
						<TextInput {...props} source="city" fullWidth />
					</Grid>
					<Grid item xs={12} className={classes.gridItem}>
						<TextInput {...props} source="country" fullWidth />
					</Grid>
				</Grid>
				<Grid container spacing={3}>
					<Grid item xs={6} className={classes.gridItem}>
						<TextInput {...props} source="lat" fullWidth />
					</Grid>
					<Grid item xs={6} className={classes.gridItem}>
						<TextInput {...props} source="lng" fullWidth />
					</Grid>
				</Grid>
			</Grid>
			{values?.lat && values?.lng && (
				<Grid item md={6} className={classes.gridMap}>
					<GoogleMapComponent
						lat={values.lat}
						lng={values.lng}
						onClick={handleClick}
						{...props}
					/>
				</Grid>
			)}
		</Grid>
	);
};

export default SiteAddressInputsMap;
