import React from 'react';
import { TextInput, DateInput, ReferenceInput, AutocompleteInput, FormDataConsumer, BooleanInput } from 'react-admin';

import Edit from '../common/Edit';
import SimpleForm from '../common/SimpleForm';

import ProductItemRentSoldInput from './inputs/ProductItemRentSoldInput';
import ProductItemArchiveButton from './ProductItemActionsButtons/ProductItemArchiveButton';
import ProductItemSupplierField from './inputs/ProductItemSupplierInput';


const optionText = choice => choice && choice.reference ? choice.reference : '';
const customerOptionText = choice => choice && choice.name ? choice.name : '';
const siteOptionText = choice => choice && choice.name ? choice.name : '';

const ProductItemEdit = ({ classes, ...props }) => (
    <Edit {...props} archiveButton={<ProductItemArchiveButton />}>
        <SimpleForm>
            <TextInput source="id" disabled />
            <TextInput source="serial" disabled />
            <ReferenceInput label="Product" source="product_id" reference="device_products" filter={{ managed: false }} sort={{ field: 'name', order: 'ASC' }} allowEmpty>
                <AutocompleteInput optionText={optionText} />
            </ReferenceInput>
            <ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} allowEmpty>
                <AutocompleteInput optionText={customerOptionText} />
            </ReferenceInput>
            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    <ReferenceInput label="Site" source="site_id" reference="sites" filter={formData.customer_id && { customer_id: formData.customer_id }} {...rest} allowEmpty>
                        <AutocompleteInput optionText={siteOptionText} />
                    </ReferenceInput>
                )}
            </FormDataConsumer>
            <BooleanInput source="reserved" />
            <BooleanInput source="is_new" />
            <ProductItemRentSoldInput source="rent_sold" label="VENN Billing Mode" />
            <ProductItemSupplierField />
            <TextInput source="power_supply_serial" />
            <TextInput source="router_serial" />
            <TextInput source="account_number" />
            <TextInput source="account_email" />
            <DateInput source="order_date" />
            <DateInput source="installed_date" />
            <TextInput source="notes" options={{ multiline: true }} rows="10" />
        </SimpleForm>
    </Edit>
);

export default ProductItemEdit;
