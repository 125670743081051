import React from 'react';
import { TextInput, BooleanInput, ReferenceInput, AutocompleteInput, useListContext } from 'react-admin';

import GetAppIcon from '@material-ui/icons/GetApp';

import List, { ListActions, BulkActions } from '../common/List';
import Filter from '../common/Filter';
import IconButton from '../common/IconButton';
import ServiceRow from './rows/ServiceRow';
import ServiceDeliveryRow from './rows/ServiceDeliveryRow';
import ServiceTypeField from './ServiceTypeField';
import ServiceFrequencyField from './ServiceFrequencyField';
import ServiceStatusField from './ServiceStatusField';
import ServiceBulkShip from './buttons/ServiceBulkShip';
import ServiceBulkDeliver from './buttons/ServiceBulkDeliver';
import ServiceQueueField from './ServiceQueueField';

import { API_URL } from '../../services/settings';

const optionText = choice => choice.reference ? choice.reference : '';
const userOptionText = choice => choice && choice.email ? choice.email : '';

const ServiceListActions = (props) => {
    return (
        <ListActions {...props}>
            <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/services/export.xls`}>Export</IconButton>
        </ListActions>
    );
};

const ServiceFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ServiceTypeField label="Type" source="type" alwaysOn />
        <ReferenceInput label="Package" source="package_id" reference="packages" sort={{ field: 'reference', order: 'ASC' }} allowEmpty alwaysOn>
            <AutocompleteInput optionText={optionText} />
        </ReferenceInput>
        <ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
            <AutocompleteInput />
        </ReferenceInput>
        <ServiceFrequencyField label="Frequency" source="frequency" />
        <ServiceStatusField label="Status" source="status" alwaysOn />
        <BooleanInput label="Archived" source="archived" alwaysOn />
        <BooleanInput label="Not Invoiced" source="not_invoiced" />
        <BooleanInput label="To Invoice" source="to_invoice" />
        <ServiceQueueField label="Queue" source="queue" />
        <ReferenceInput label="Assigned To" source="assignee_id" reference="users" filter={{ is_staff: true }} sort={{ field: 'name', order: 'ASC' }} allowEmpty >
            <AutocompleteInput optionText={userOptionText} />
        </ReferenceInput>
    </Filter>
);

const filterDefaultValues = {
    archived: false,
};

const ServiceBulkActionsButtons = (props) => { 
    const { filterValues } = useListContext();
    const status = filterValues.status

    if (status === 'provisioned') {
        return (<ServiceBulkShip {...props} />);
    } else if (status === 'shipped') {
        return (<ServiceBulkDeliver {...props} />); 
    } else {
        return (<BulkActions {...props} />);
    }
};

const ServiceListComponent = (props) => {
    const { filterValues } = useListContext();
    const isForDelivery = filterValues.queue !== undefined && filterValues.queue !== '';
    const status = filterValues.status;

    if (isForDelivery && status === 'provisioning') {
        return (<ServiceDeliveryRow {...props} exclude={['device', 'line', 'tracking_url']}/>);
    }

    return (
        <>
            {isForDelivery ? (
                <ServiceDeliveryRow {...props} />
            ) : (
                <ServiceRow {...props} exclude={['start_date', 'end_date']} />
            )}</>
    )
};


const ServiceList = (props) => (
    <List {...props} filters={<ServiceFilter />} filterDefaultValues={filterDefaultValues} perPage={50} actions={<ServiceListActions />} exporter={false} bulkActionButtons={<ServiceBulkActionsButtons />}>
        <ServiceListComponent />
    </List>
);

export default ServiceList;
