import React from 'react';
import { Datagrid, TextField, DateInput, SelectInput, Pagination, ReferenceInput, AutocompleteInput, useTranslate } from 'react-admin';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { useForm } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';

import List, { ListActions } from '../common/List';
import Filter from '../common/Filter';
import DateField from '../common/fields/DateField';
import IconButton from '../common/IconButton';
import SplitButton from '../common/SplitButton';
import StarlinkUsageReportGraph from './StarlinkUsageReportGraph';

import { API_URL } from '../../services/settings';


const useStyles = makeStyles((theme) =>  ({
	splitButton: {
		'& button': {
			borderColor: 'rgba(0, 0, 0, 0.23)',
			borderRadius: '4px',
			height: '40px'
		}
	}
}));

const customerOptionText = choice => choice.name ? choice.name : '';

const groupByChoices = [
	{ id: 'customer', name: 'Customer' },
	{ id: 'site', name: 'Site' },
	{ id: 'date', name: 'Date'}
];

const StarlinkUsageReportListActions = (props) => {
	const params = new URLSearchParams();
	params.append('format', 'xls');
	for (const key in props.filterValues) {
		params.append(key, props.filterValues[key]);
	}

	return (
		<ListActions {...props}>
			<IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/starlink_usages/report?${params.toString()}`}>Export</IconButton>
		</ListActions>
	);
};

const DateButtons = () => {
	const form = useForm();
	const translate = useTranslate();
	const classes = useStyles();

	const handleClickThisMonth = () => {
		form.change('start_date', moment().startOf('month').format('YYYY-MM-DD'));
		form.change('end_date', moment().format('YYYY-MM-DD'));
	};

	const handleClickLastMonth = () => {
		form.change('start_date', moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
		form.change('end_date', moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
	};

	const handleClickLast6Months = () => {
		form.change('start_date', moment().subtract(5, 'months').startOf('month').format('YYYY-MM-DD'));
		form.change('end_date', moment().format('YYYY-MM-DD'));
	};

	const handleClickLast12Months = () => {
		form.change('start_date', moment().subtract(11, 'months').startOf('month').format('YYYY-MM-DD'));
		form.change('end_date', moment().format('YYYY-MM-DD'));
	};

	return (
		<Box mb={1}>
			<SplitButton className={classes.splitButton} closeOnButtonClick>
				<Button onClick={handleClickThisMonth}>{translate('This month')}</Button>
				<Button onClick={handleClickLastMonth}>{translate('Last month')}</Button>
				<Button onClick={handleClickLast6Months}>{translate('Last 6 months')}</Button>
				<Button onClick={handleClickLast12Months}>{translate('Last 12 months')}</Button>
			</SplitButton>
		</Box>
	);
};

const StarlinkUsageFilter = (props) => (
	<Filter {...props}>
		<ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
			<AutocompleteInput optionText={customerOptionText} />
		</ReferenceInput>
		<SelectInput source="group_by" choices={groupByChoices} alwaysOn />
		<DateInput source="start_date" alwaysOn />
		<DateInput source="end_date" alwaysOn />
		<DateButtons source="date" alwaysOn />
	</Filter>
);

const StarlinkUsageReportRow = ({ data, ...props }) => {
	const firstRecord = data[0];

	if(!firstRecord) {
		return null;
	}

	const records = Object.values(data).slice(0, props.total);

	return (
		<>
			<Typography variant="h6" align="right" style={{ padding: '10px' }}>Total: {firstRecord.total_usage} GB </Typography>
			<StarlinkUsageReportGraph data={records} groupBy={props.filterValues.group_by} />
			<Datagrid {...props} data={data}>
				{'customer' in firstRecord && <TextField source="customer" />}
				{'site' in firstRecord && <TextField source="site" />}
				{'date' in firstRecord && <DateField source="date" /> }
				<TextField source="usage" label="GB" />
			</Datagrid>
		</>
	);
};

const StarlinkUsageReportPagination = (props) => (
	<Pagination {...props} rowsPerPageOptions={[props.total]} perPage={props.total} />
);

const StarlinkUsageReportList = ({ staticContext, ...props }) => (
	<List {...props}>
		<StarlinkUsageReportRow />
	</List>
);

StarlinkUsageReportList.defaultProps = {
	title: "Starlink Usage reports",
	resource: "starlink_usages/report",
	basePath: "starlink_usage/report",
	hasList: true,
	hasShow: false,
	hasCreate: false,
	hasEdit: false,
	filters: <StarlinkUsageFilter />,
	filterDefaultValues: { start_date: moment().startOf('month').format('YYYY-MM-DD'), end_date: moment().format('YYYY-MM-DD'), source: 'incontrol' },
	bulkActionButtons: false,
	actions: <StarlinkUsageReportListActions />,
	exporter: false,
	pagination: <StarlinkUsageReportPagination />
};

export default StarlinkUsageReportList;
