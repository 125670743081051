import React from 'react';
import { AutocompleteInput } from 'react-admin';

const choices = [
	{ id: 'UPSELL', name: 'UPSELL' },
	{ id: 'DOWNSELL', name: 'DOWNSELL' },
	{ id: 'RMA', name: 'RMA' },
	{ id: 'OTHER', name: 'OTHER' },
];

const ServiceEndReasonInput = ({ classes, record, source = "end_reason", ...props }) => {
	if (record && record[source] && !choices.find((provider) => provider.id === record[source])) {
		choices.push({ id: record[source], name: record[source] });
	}

	return (
		<AutocompleteInput choices={choices} source={source} record={record} allowEmpty {...props} />
	);
};

export default ServiceEndReasonInput;
